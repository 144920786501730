import {useEffect, useState, useRef} from 'react'
import 'bootstrap/dist/js/bootstrap.min.js'
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ReactLenis } from "@studio-freight/react-lenis";
import './style.css'

gsap.registerPlugin(ScrollTrigger);

const ProcessAccordion = () => {
  const accordionRef = useRef(null);
  useEffect(() => {
    let ctx = gsap.context(() => {
      const accordion = accordionRef.current;

      gsap.from(accordion, {
        opacity:0,
        y: 100,
        duration:1,
        ease: "power3",
        transformOrigin: "center top",
        scrollTrigger: {
          trigger: accordion,
          start: "top 95%",
          end: "bottom",
          toggleActions: "play reverse play reverse",
        },
      });
      
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 100);
  })

    return () => ctx.revert();
  }, []);
  return (
    <ReactLenis root options={{ smoothTouch: true }}>
      <div ref={accordionRef} className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button className="accordion-button" curr_index="1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            Project Management
            </button>
          </h3>
          <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <h3>We provide a comprehensive, fully integrated service covering planning, design, and construction from start to finish.</h3>
              <p>At Constec, we’re engaged in every phase of the project, from space planning to selecting interior design finishes, from concept boards to pre-construction, and from construction to final project delivery.<br/>
              We add value at every step of the process. Teaming up with top architects, designers, and consultants in the industry, we oversee your project from inception to completion, assuming full responsibility for the entire scope of work.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button className="accordion-button collapsed" curr_index="2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            Construction Management
            </button>
          </h3>
          <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div className="accordion-body">                 
              <h3>We specialize in managing the construction aspect of your project.</h3>
              <p>Our team seamlessly integrates our expertise to collaborate with your chosen designers and consultants to bring your vision to life. At Constec, we work closely with your selected team, making joint decisions to optimize the project’s success.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button className="accordion-button collapsed" curr_index="3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            General Contracting
            </button>
          </h3>
          <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div className="accordion-body">                 
              <h3>We meticulously ensure that every essential aspect of your project is executed flawlessly for a seamless construction process.</h3>
              <p>Tailored for business owners, our service encompasses the entire spectrum of construction needs, from paint and flooring to HVAC, millwork, engineering, electrical, plumbing, and beyond. Its widespread acclaim arises from its comprehensive approach, guaranteeing a hassle-free experience throughout the construction journey.</p>
              <p className="has-margin">Rest assured, our unwavering commitment to excellence leaves no room for compromise. With a dedication to delivering top-tier service without exception, we provide proficient project management, invaluable consultations, adept sub-trade management, astute problem-solving, thorough permitting, and meticulous inspections.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button className="accordion-button collapsed" curr_index="4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
            Commercial Construction
            </button>
          </h3>
          <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div className="accordion-body">                 
              <h3>Embarking on projects from the ground up is our specialty at Constec Design & Contracting. </h3>
              <p>We specialize in transforming vacant commercial spaces into refined environments suitable for offices, retail, hospitality, healthcare, and education. As a design-build firm, we handle the entire construction process and offer comprehensive services such as plan development, material selection, and pre-construction permit assistance. With a proven track record of over 20 successful commercial projects and extensive residential experience, we bring over a decade of combined expertise to every endeavor. Our discerning clientele includes business owners seeking top-tier construction services.</p>
              <p className="has-margin">At Constec Design & Contracting, we pride ourselves on delivering excellence in every project, ensuring that further details are unnecessary as we remain committed to surpassing expectations without compromise.</p>
            </div>
          </div>
        </div>
      </div>
    </ReactLenis>
  )
}

export default ProcessAccordion
