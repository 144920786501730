import {useEffect} from 'react'
import axios from 'axios'
import './style.css'

const EnquiryForm = () => {

  function serializeForm(form) {
    const formData = new FormData(form);
    const params = new URLSearchParams();
    for (const [key, value] of formData) {
      params.append(key, value);
    }
    return params.toString();
  }
  
 const handleSubmit = (event)=>{
  event.preventDefault();
  const form = event.target;
  const serializedData = serializeForm(form);

  // console.log(serializedData)
  axios.post('https://constec-backend-psi.vercel.app/signup', serializedData)
  .then(response => {
    console.log(response.data)
    document.querySelector('#signup-message').textContent = "Thank you for contacting us! We will be in touch with you shortly.";
    document.querySelector('#signup-form').reset(); // Clear the form
  })
  .catch(error => {
    document.querySelector('#signup-message').textContent = 'Signup failed.';
  });

 }

  return (
    <div className="signup-container">
      <form className="signup" id="signup-form"  onSubmit={handleSubmit}>
        <div className="container nameWrapper">
            <div className="row wrapper">
              <div className="col-12 col-md-6 left">
                <input type="text" placeholder="First Name*" id="first-name" name="firstName" required/>
              </div>
              <div className="col-12 col-md-6 right">
                <input type="text" placeholder="Last Name*" id="last-name" name="lastName" required/>
              </div>
          </div>
        </div>
        <input type="email" placeholder="Email*" id="email" name="email" required/>
        <textarea id="enquiry" placeholder="Your enquiry*" name="enquiry" required></textarea>
        <button type="submit" className="submit-btn">Submit</button>
      </form>
      <p>By signing up to our newsletter you agree to our Terms and Conditions</p>
      <div id="signup-message"></div>
    </div>
  )
}

export default EnquiryForm
