import { Link, useParams, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Banner from "../../components/common/Banner";
// import ThumbnailsFlickity from "./ThumbnailsFlickity";
import ProjectsFlickity from "../../components/common/ProjectsFlickity";
import Flickity from "react-flickity-component";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { projects } from "../../components/Data";
import { ReactLenis } from "@studio-freight/react-lenis";
import "./style.css";

gsap.registerPlugin(ScrollTrigger);

const ProjectsDetail = () => {
  const params = useParams();

  const activeProject = projects.filter(
    (element) => Number(element.id) === Number(params.id)
  )[0];

  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      window.scrollTo(0, 0);
    }

    let ctx = gsap.context(() => {

      // textanimate
      gsap.from(".textAnimate", {
        opacity: 0,
        y: 100,
        duration: 1,
        ease: "power3",
        transformOrigin: "center top",
        scrollTrigger: {
          trigger: ".textAnimate",
          start: "top 90%",
          end:"bottom",
          toggleActions: "play reverse play reverse",
        },
      });
      // imgAnimate
      let imgAnimates = gsap.utils.toArray(".imgAnimate");
      imgAnimates.forEach((imgAnimate) => {
        gsap.from(imgAnimate, {
          autoAlpha: 0,
          duration: 1,
          scrollTrigger: {
            trigger: imgAnimate,
            start: "top 80%",
            //end: "top -100%",
            end: "bottom",
            toggleActions: "play reverse play reverse",
          },
        });
      });

      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 100);
    });

    return () => ctx.revert();
  }, []);
  const imgListNode = [];
  let flkty;
  const flickityOptions = {
    // initialIndex: 1,
    wrapAround: true,
    selectedAttraction: 0.03,
    friction: 0.5,
    //cellAlign: "left", **cannot add if want initialindex 0**
    verticalCells: true,
    pageDots: true,
    arrowShape:
      "M0.541,32.7493 C0.526,32.7843 0.521,32.8213 0.507,32.8563 C0.189,33.6663 0,34.5413 0,35.4633 C0,36.3853 0.189,37.2603 0.507,38.0693 C0.521,38.1043 0.526,38.1423 0.541,38.1773 C0.904,39.0593 1.433,39.8533 2.097,40.5183 L30.413,68.8333 C33.203,71.6243 37.723,71.6243 40.513,68.8333 C43.304,66.0433 43.304,61.5233 40.513,58.7333 L24.386,42.6053 L92.857,42.6053 C96.803,42.6053 100,39.4063 100,35.4633 C100,31.5203 96.803,28.3203 92.857,28.3203 L24.386,28.3203 L40.513,12.1923 C43.304,9.4023 43.304,4.8833 40.513,2.0933 C39.118,0.6973 37.291,0.0003 35.463,0.0003 C33.636,0.0003 31.808,0.6973 30.413,2.0933 L2.097,30.4083 C1.433,31.0723 0.904,31.8673 0.541,32.7493",
  };
  useEffect(() => {
    let carouselWidth = document.querySelector('.galleryCarousel').offsetWidth
    // console.log(imgListNode)
    // based on the first cover img size to calculate the height for all images
    let imgHeight = (carouselWidth * 710 ) /1194
    console.log(`init${imgHeight}`)
    imgListNode.forEach(img =>{
      img.style.height = `${imgHeight}px`
    })
  }, [imgListNode]);

  return (
    <ReactLenis root options={{ smoothTouch: true }}>
      <main className="projectsDetailPage">
        <Banner imgPath={activeProject.pathL} heading={activeProject.heading} />
        <div
          style={{
            position: "relative",
            zIndex: 2,
            overflow: "hidden",
            background: "#FFFEFA",
          }}
        >
          <section className="projectsDetail-section ppadding">
            <div className="global-padding">
              <div className="container">
                <div className="row intro">
                  <div className="col-12 col-md-8 col-lg-6 left">
                    <div className="wrapper textAnimate">
                      <h1>{activeProject.heading}</h1>
                      <h4>{activeProject.location}</h4>
                      {activeProject.detail ? (
                        <p>{activeProject.detail}</p>
                      ) : null}
                    </div>
                  </div>
                  {activeProject.introThumb ? (
                    <div className="col-12 col-md-8 col-lg-6 right">
                      <img
                        className="imgAnimate"
                        src={activeProject.introThumb}
                        alt={activeProject.heading}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row flickity">
                  <div className="imgAnimate">
                    {/* <ThumbnailsFlickity thumbnails={activeProject.thumbnails} /> */}
                    <Flickity
                      flickityRef={(c) => {
                        flkty = c;
                        flkty.on(
                          "staticClick",
                          function (
                            event,
                            pointer,
                            cellElement,
                            moveVector,
                            cellIndex
                          ) {
                            if (pointer.clientX < window.innerWidth / 2) {
                              if (moveVector === 0) {
                                moveVector = activeProject.thumbnails.length;
                              }
                              flkty.select(moveVector - 1);
                            } else {
                              if (
                                moveVector ===
                                activeProject.thumbnails.length - 1
                              ) {
                                moveVector = -1;
                              }
                              flkty.select(moveVector + 1);
                            }
                          }
                        );
                      }}
                      className={"carousel galleryCarousel"} // default ''
                      elementType={"div"} // default 'div'
                      options={flickityOptions} // takes flickity options {}
                      // disableImagesLoaded={false} // default false
                      // reloadOnUpdate // default false
                      // static // default false
                    >
                      {activeProject.thumbnails.map((thumbnail, index) => {
                        return (
                          <div className="img-wrapper is-relative" key={index}>
                            <img
                              ref={(c) => imgListNode.push(c)}
                              className="img"
                              src={thumbnail}
                              alt="Project gallery"
                            />
                          </div>
                        );
                      })}
                    </Flickity>
                  </div>
                </div>

                {activeProject.videoPath ? (
                  <div className="row video">
                    <div className="bg-video-container">
                      {activeProject.videoPath.map((video, index) => {
                        return (
                          <video
                            className="bg-video imgAnimate"
                            poster={video.poster}
                            preload="metadata"
                            // autoPlay
                            // loop
                            // muted
                            playsInline
                            controls
                            key={video.path}
                          >
                            <source src={video.path} type="video/mp4" />
                          </video>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </section>
          <section className="projectsCarouselSection ppadding">
            <div className="projects-carousel">
              <h2>Our Projects</h2>
              <ProjectsFlickity />
            </div>
          </section>
        </div>
      </main>
    </ReactLenis>
  );
};

export default ProjectsDetail;
