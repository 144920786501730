import {useEffect, useState} from 'react'
import 'bootstrap/dist/js/bootstrap.min.js'
import './style.css'

const CompanyAccordion = () => {
  return (
    <div className="companyAccordion">
      <div className="accordion" id="accordionExampleCompany">
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button className="accordion-button" curr_index="1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            Plan
            </button>
          </h3>
          <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExampleCompany">
            <div className="accordion-body">
              <ul>
                <li>No-obligation consultation</li>
                <li>Site walkthrough</li>
                <li>Feasibility Plan</li>
                <li>Space Planning</li>
                <li>Budgeting</li>
                <li>Plan Approvals</li>
              </ul>
              <p>We collaborate closely with our clients to deeply grasp their organizational and cultural requirements. Our mission is to translate aspirations into reality. Recognizing that planning is pivotal, we prioritize capturing perfection from the project’s outset. This dedication enables us to deliver superior quality spaces through our comprehensive construction management process.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button className="accordion-button collapsed" curr_index="2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            Design
            </button>
          </h3>
          <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExampleCompany">
            <div className="accordion-body">                 
              <ul>
                <li>Interior Design Finishes</li>
                <li>Concept Boards</li>
                <li>Permit Drawings</li>
                <li>City Requirements</li>
                <li>Tender Drawings</li>
                <li>Construction Cost</li>
              </ul>
              <p>Our design ethos is rooted in understanding your identity, values, and brand essence. Constec offers comprehensive interior design services, from space planning to detailed drawings for permits and construction. Our team seamlessly integrates city requirements into the design process, resulting in a bespoke commercial space that authentically represents your brand.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button className="accordion-button collapsed" curr_index="3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            Build
            </button>
          </h3>
          <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExampleCompany">
            <div className="accordion-body">                 
              <ul>
                <li>Project Management <span className="is-italic">(Design-Build Approach)</span></li>
                <li>Construction Management <span className="is-italic">(Collaborative Approach)</span></li>
                <li>Construction Timeline</li>
                <li>Cost Saving Solutions</li>
                <li>IT Solutions</li>
                <li>City Inspections</li>
                <li>Client Walkthrough</li>
                <li>Quality Control</li>
              </ul>
              <p>We handle every aspect of your project, from start to finish. This includes managing permits, insurance, budget, schedule, and subcontractor selection and oversight. We validate plans and specifications, manage tender calls with suppliers and subcontractors, and administer contracts. With rigorous schedule and budget management, our approach minimizes risk throughout the process.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyAccordion
