import { useEffect, useRef, useState } from "react";
import Heading from "../../components/common/Heading";
import ProjectsList from "./ProjectsList";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ReactLenis } from "@studio-freight/react-lenis";
import "./style.css";

gsap.registerPlugin(ScrollTrigger);

const Floorplans = () => {
  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <ReactLenis root options={{ smoothTouch: true }}>
      <main className="projectsPage ppadding">
        <section className="global-padding projects-section">
          <Heading
            title={[
              "Regardless of the project, our team relentlessly pushes boundaries to shape the workspaces of the future. We craft interiors that enhance the experience for both a business’s employees and clients. Every aspect, from space planning to design, construction, and quality control, receives meticulous attention and consideration.",
            ]}
          />
            <ProjectsList />
        </section>
      </main>
    </ReactLenis>
  );
};

export default Floorplans;
