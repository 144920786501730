import { createBrowserRouter } from "react-router-dom";
import Root from "../pages/Root";
import Homepage from "../pages/Homepage";
import Projects from "../pages/Projects";
import ProjectsDetail from "../pages/ProjectsDetail";
import Company from "../pages/Company";
import Contact from "../pages/Contact";
import Privacy from "../pages/Privacy";

const routes = [
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Homepage />,
      },
      {
        path: "/projects",
        element: <Projects />,
      },
      {
        path: "/projects-detail/:id",
        element: <ProjectsDetail />,
      },
      {
        path: "/company",
        element: <Company />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/privacy-legal",
        element: <Privacy />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);
export default router;
