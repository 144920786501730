import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import EnquiryForm from "./EnquiryForm";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ReactLenis } from "@studio-freight/react-lenis";
import "./style.css";

const Contact = () => {
  const [matches, setMatches] = useState(false);
  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      window.scrollTo(0, 0);
      setMatches(true)
    }
  },[]);
  return (
    <ReactLenis root options={{ smoothTouch: true }}>
      <main className="ppadding contactPage">
        <div className="global-padding">
          <div className="container">
            <div className="row first-row">
              <div className="col-12 col-md-4 left">
                <h1>Connect with us</h1>
                <p>
                  We eagerly anticipate aligning visions and crafting your space
                  for a brighter future.
                </p>
                <Link to="mailto: Joshua@constec.ca" className="email">
                Joshua@constec.ca
                </Link>
                {matches ? null : <div><Link
                  to="https://www.instagram.com/constecdesigns/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="instagram"
                >
                  Instagram
                </Link>
                <Link
                  to="https://www.facebook.com/constecdesign/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="facebook"
                >
                  Facebook
                </Link></div>}
                
              </div>
              <div className="col-12 col-md-6 right">
                <EnquiryForm />
              </div>
            </div>
            {matches ? null : 
            <div className="row copyright-row">
              <div className="col-12 col-md-6 left">
                <Link to="/privacy-legal">Privacy & Legal</Link>
                <div className="copyright">
                  © 2024 Constec Design & Contracting Ltd. All rights reserved.
                </div>
              </div>
              <div className="col-12 col-md-6 right">
                <Link
                  to="https://celero.site/s/c5d2b767/alab-creative-presentation-2024/?page=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Website by <span className="is-bold">ALab Creative Inc</span>.
                </Link>
              </div>
            </div>}
          </div>
        </div>
      </main>
    </ReactLenis>
  );
};

export default Contact;
