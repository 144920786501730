import { useEffect, useRef, useState } from "react";
import Heading from "../../components/common/Heading";
import CompanyAccordion from "./CompanyAccordion";
import Intro from "../../components/common/Intro";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ReactLenis } from "@studio-freight/react-lenis";
import "./style.css";

gsap.registerPlugin(ScrollTrigger);

const Company = () => {
  const [matches, setMatches] = useState(false);
  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      window.scrollTo(0, 0);
      setMatches(true)
    }

    let ctx = gsap.context(() => {
      // imgAnimate
      gsap.from(".imgAnimate", {
        autoAlpha: 0,
        duration: 1,
        scrollTrigger: {
          trigger: ".imgAnimate",
          start: "top 80%",
          end: "bottom -100%",
          toggleActions: "play reverse play reverse",
        },
      });
      // textAnimate
      let textAnimates = gsap.utils.toArray(".textAnimate");
      textAnimates.forEach((textAnimate) => {
        gsap.from(textAnimate, {
          opacity: 0,
          y: 100,
          duration: 1,
          ease: "power3",
          transformOrigin: "center top",
          scrollTrigger: {
            trigger: textAnimate,
            start: "top 92%",
            end: "bottom -100%",
            toggleActions: "play reverse play reverse",
          },
        });
      });
      
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 100);
    });

    return () => ctx.revert();
  }, []);
  return (
    <ReactLenis root options={{ smoothTouch: true }}>
      <main className="companyPage">
        <section className=" companyIntro ppadding">
          <div className="global-padding">
            <Heading
              title={[
                "Constec Design & Contracting was founded with a singular mission: to deliver comprehensive interior fit-outs that cultivate dynamic and captivating work environments for businesses.",
                "We are firm believers that such environments foster collaboration between employees and management, ultimately leading to heightened business efficiency, productivity, and employee retention. Consequently, this synergy drives overall business performance and enhances customer engagement.",
              ]}
            />
          </div>
        </section>
        <section className=" companyDetail ppadding">
          <div className="global-padding">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 left textAnimate">
                  <h4>Our mission</h4>
                  <div className="mission-sub">
                    We’re driven by a mission to revolutionize the construction
                    of workspaces.
                  </div>
                </div>
                <div className="col-12 col-md-6 right">
                  <img
                    className="imgAnimate"
                    src="../images/company/constec.jpg"
                    alt="constec"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 left textAnimate">
                  <h4>Our services</h4>
                  <p>
                    At Constec, we assume full project control, managing all
                    subcontractors for you. Whether it’s expediting permit
                    approvals with city authorities or obtaining essential
                    paperwork for final occupancy, our team has successfully
                    navigated multiple municipalities, addressing any potential
                    city-related issues along the way.
                  </p>
                </div>
                <div className="col-12 col-md-6 right textAnimate">
                  <CompanyAccordion />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" whyUs">
          <div className="ppadding global-padding">
            <h4>Why us</h4>
            {matches ? 
            <div className="mission-sub textAnimate">
              What distinguishes <br /> Constec Design &<br />  Contracting from
              others?
            </div> : 
            <div className="mission-sub textAnimate">
              What distinguishes <br /> Constec Design & Contracting <br /> from
              others?
            </div>}
            <ul className="textAnimate">
              <li>Our commitment to top-notch service</li>
              <li>The cutting-edge spaces we create</li>
              <li>The client expectations we exceed</li>
              <li>We uphold transparency as a core value</li>
            </ul>
          </div>
        </section>
        <section className="ppadding connect">
          <div className="global-padding">
            <h4>Connect with us</h4>
            <Intro
              contents="We guarantee that your environment mirrors a dedication to excellence, well-being, and the values of your projects. Reach out to us to synchronize visions and fashion a space that endures the test of time."
              sub="Contact us"
              path="/contact"
            />
          </div>
        </section>
      </main>
    </ReactLenis>
  );
};

export default Company;
