import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ReactLenis } from "@studio-freight/react-lenis";
import "./style.css";

gsap.registerPlugin(ScrollTrigger);

const Heading = ({ title }) => {
  const titleRef = useRef(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      const title = titleRef.current;

      gsap.from(title, {
        opacity: 0,
        y: 100,
        duration: 1,
        ease: "power3",
        transformOrigin: "center top",
        scrollTrigger: {
          trigger: title,
          start: "top 88%",
          end: "top -100%",
          toggleActions: "play reverse play reverse",
          //scrub: 0.5,
        },
      });
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 100);
    });

    return () => ctx.revert();
  }, []);

  return (
    <ReactLenis root options={{ smoothTouch: true }}>
      <h1 ref={titleRef}>
        {title.map((titleItem, index) => {
          return <span key={index}>{titleItem}</span>;
        })}
      </h1>
    </ReactLenis>
  );
};

export default Heading;
