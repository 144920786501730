import { useState, useEffect, useRef } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.min.js";
import { nav } from "../../Data";
import gsap from "gsap";
import { useLenis } from "@studio-freight/react-lenis";
import "./style.css";

const Header = () => {
  // store all Nav node  // off canvas
  const nodeListRef = [];
  const offcanvasNode = useRef();
  const [matches, setMatches] = useState(false);
  const [scroll, setScroll] = useState(false);

  // off canvas
  const handleMouseover = (e) => {
    // reset all text opacity to 1
    resetAllTextOpacity();

    // make the active nav item opacity to 0.35
    e.target.style.opacity = 0.4;
  };
  // nav: all text opacity become 1
  const resetAllTextOpacity = () => {
    nodeListRef.forEach((nodeList) => {
      nodeList.style.opacity = 1;
    });
  };

  // navbar
  const location = useLocation();
  let scrollPos = 0;

  const [isWhite, setColor] = useState({
    isLogoWhite: true,
    textColor: "#FFFFFF",
    headerBgColor: "transparent",
    // transform: "translateY(0%)",
    // opacity: 1,
  });

  useLenis(({ scroll }) => {
    // handle scroll -- page with banner
    const scrollhandle = () => {
      if (scroll < window.innerHeight) {
        setColor({
          isLogoWhite: true,
          textColor: "#FFFFFF",
          headerBgColor: "transparent",
          transform: "translateY(0%)",
          opacity: 1,
        });
        setScroll(false)
      } else if (scroll > scrollPos) {
        setColor({
          isLogoWhite: false,
          textColor: "#000000",
          headerBgColor: "#FFFFFF",
          transform: "translateY(-100%)",
          opacity: 0,
        });
        setScroll(true)
      } else if (scroll < scrollPos) {
        setColor({
          isLogoWhite: false,
          textColor: "#000000",
          headerBgColor: "#FFFFFF",
          transform: "translateY(0%)",
          opacity: 1,
        });
        setScroll(true)
      }
      scrollPos = scroll;
    };
    // handle scroll -- page without banner
    const scrollhandleNoBnr = () => {
      if (scroll === 0) {
        if (location.pathname === "/contact") {
          setColor({
            isLogoWhite: true,
            textColor: "#FFFFFF",
            headerBgColor: "transparent",
            transform: "translateY(0%)",
            opacity: 1,
          });
        } else {
          setColor({
            isLogoWhite: false,
            textColor: "#000000",
            headerBgColor: "transparent",
            transform: "translateY(0%)",
            opacity: 1,
          });
        }
        
        setScroll(false)
      } else if (scroll > scrollPos) {
        if (location.pathname === "/contact") {
          setColor({
            isLogoWhite: true,
            textColor: "#FFFFFF",
            headerBgColor: "#000000",
            transform: "translateY(-100%)",
            opacity: 0,
          });
        } else {
          setColor({
            isLogoWhite: false,
            textColor: "#000000",
            headerBgColor: "#FFFFFF",
            transform: "translateY(-100%)",
            opacity: 0,
          });
        }
        
        setScroll(true)
      } else if (scroll < scrollPos) {
        if (location.pathname === "/contact") {
          setColor({
            isLogoWhite: true,
            textColor: "#FFFFFF",
            headerBgColor: "#000000",
            transform: "translateY(0%)",
            opacity: 1,
          });
        } else {
          setColor({
            isLogoWhite: false,
            textColor: "#000000",
            headerBgColor: "#FFFFFF",
            transform: "translateY(0%)",
            opacity: 1,
          });
        }
        
        setScroll(true)
      }
      scrollPos = scroll;
    };

    // HEADER COLOR CHANGE
    if (
      location.pathname === "/projects" ||
      location.pathname === "/company" ||
      location.pathname === "/privacy-legal"
    ) {
      setColor({
        isLogoWhite: false,
        textColor: "#000000",
        headerBgColor: "transparent",
        transform: "translateY(0%)",
        opacity: 1,
      });

      scrollhandleNoBnr();
    } else {
      setColor({
        isLogoWhite: true,
        textColor: "#FFFFFF",
        headerBgColor: "transparent",
        transform: "translateY(0%)",
        opacity: 1,
      });
      if (location.pathname === "/contact") {
        scrollhandleNoBnr();
      } else {
        scrollhandle();
      }
    }
  });

  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      window.scrollTo(0, 0);
      setMatches(true);
    }

    // off canvas,-- nav bar opacity change to 0.4
    offcanvasNode.current.addEventListener("show.bs.offcanvas", () => {
      setColor({
        isLogoWhite: true,
        textColor: "#FFFFFF",
        headerBgColor: "rgba(0,0,0,0.4)",
      });
    });
  }, [location.pathname]);

  return (
    <>
      <header
        className="header"
        style={{
          background: isWhite.headerBgColor,
          transform: isWhite.transform,
        }}
      >
        <div className="wrapper">
          {matches && location.pathname === "/" ? scroll ? null : (
            <Link
              to="/contact"
              className="menu-text contact-text"
              style={{ color: isWhite.textColor }}
            >
              Contact
            </Link>) : matches ? null : (
            <Link
              to="/contact"
              className="menu-text"
              style={{ color: isWhite.textColor }}
            >
              Contact
            </Link>)
          }
          {matches && location.pathname === "/" ? (
            scroll ? (
              <Link to="/">
                <svg
                  className={isWhite.isLogoWhite ? "logo" : "logo black"}
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 176.4 61.3"
                  style={{ enableBackground: "new 0 0 176.4 61.3" }}
                  xmlSpace="preserve"
                >
                  <g>
                    <g>
                      <path
                        className="st0"
                        d="M27,16.1c-0.4-0.5-0.9-0.8-1.4-1c-0.5-0.2-1-0.3-1.6-0.4H11.5c-0.5,0-1,0.1-1.5,0.3c-0.5,0.2-0.9,0.5-1.3,0.8
        c-0.5,0.5-0.8,1-1.1,1.6c-0.2,0.6-0.4,1.2-0.5,1.8v7.7c0.1,0.4,0.2,0.9,0.4,1.3c0.2,0.4,0.4,0.8,0.7,1.2c0.4,0.4,0.9,0.8,1.5,1
        c0.6,0.2,1.1,0.3,1.7,0.4H24c0.4,0,0.8-0.1,1.1-0.2c0.4-0.1,0.7-0.3,1-0.5c0.5-0.4,0.8-0.8,1-1.3c0.2-0.5,0.3-1,0.4-1.5l-0.1-0.9
        c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2,0-0.5,0-0.6,0c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0.3-0.4,0.6-0.4,1
        c0,0.4-0.1,0.7-0.2,1l-0.8,0.5H11.3c-0.2,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.4-0.7
        c-0.1-0.2-0.1-0.5-0.2-0.7v-6.8c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.6,0.5-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.2-0.1,0.4-0.2,0.7-0.2
        h12.7l0.8,0.4c0.3,0.2,0.5,0.6,0.4,1c0,0.4,0.1,0.7,0.3,1l0.6,0.4l0.7-0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.3-0.5
        c0-0.5,0-1-0.1-1.5C27.5,17,27.3,16.5,27,16.1z"
                      />
                      <path
                        className="st0"
                        d="M47.3,14.7H35.9c-1,0.1-2,0.5-2.8,1.1c-0.9,0.9-1.4,2-1.5,3.1v7.4c0.1,1.1,0.5,2,1.2,2.8
        c0.9,0.9,2,1.4,3.2,1.5h11.6c1-0.1,2-0.4,2.8-1.1c1-1,1.6-2.2,1.7-3.6v-6.7c-0.1-1.1-0.5-2.1-1.1-3C49.9,15.4,48.7,14.8,47.3,14.7
        z M49.5,26.2c-0.1,0.6-0.4,1.2-0.9,1.7l-1.1,0.4H35.9c-0.4,0-0.8-0.2-1.2-0.5c-0.5-0.5-0.8-1.1-0.8-1.8v-6.7
        c0.1-0.6,0.3-1.2,0.7-1.7c0.4-0.4,0.8-0.6,1.3-0.6h11.7c0.5,0.1,0.9,0.3,1.3,0.6c0.5,0.4,0.7,1,0.8,1.6V26.2z"
                      />
                      <path
                        className="st0"
                        d="M75.2,14.7c-0.3,0-0.6,0.1-0.9,0.3l-0.4,0.7V27L57.1,14.7c-0.7-0.2-1.1,0.1-1.2,0.9v14.2l0.2,0.4
        c0.2,0.3,0.5,0.4,0.8,0.5l0.6-0.1c0.3-0.2,0.5-0.5,0.7-0.8V18.2l16.6,12.2c0.9,0.3,1.4,0,1.5-0.9V15.8
        C76.2,15.2,75.8,14.8,75.2,14.7z"
                      />
                      <path
                        className="st0"
                        d="M83.7,30.7H96c1.1-0.1,2.1-0.5,3.1-1.1c1-0.9,1.5-2,1.6-3.3v-0.8c-0.1-1-0.4-1.9-1-2.7c-1-1-2.1-1.6-3.5-1.7
        H84.6c-0.6-0.1-1.1-0.3-1.5-0.6c-0.4-0.3-0.6-0.7-0.6-1.2c0-0.5,0.1-0.9,0.4-1.3c0.5-0.5,1-0.8,1.7-0.9H97c0.8,0.2,1.2,0.6,1.2,1
        c0,0.5,0.1,0.8,0.2,1.1c0.1,0.2,0.4,0.5,0.7,0.6c0.4,0,0.7-0.1,1-0.2c0.3-0.2,0.4-0.5,0.5-0.8c0-1.1-0.3-2-1-2.8
        c-0.8-0.7-1.7-1.1-2.7-1.2H84.7c-1.1,0.1-2.2,0.5-3.1,1.1c-0.8,0.7-1.3,1.6-1.4,2.5v1.4c0.1,0.7,0.4,1.4,0.9,2
        c1,1,2.3,1.6,3.7,1.7h11.5c0.5,0.1,0.9,0.3,1.3,0.5c0.5,0.4,0.7,0.9,0.8,1.4v0.9c-0.1,0.4-0.2,0.7-0.4,1.1c-0.5,0.5-1,0.8-1.7,0.9
        H83.7c-0.4-0.1-0.7-0.3-0.9-0.7c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.4-0.3-0.7-0.9-0.9c-0.4,0-0.7,0-0.9,0.1c-0.2,0.1-0.4,0.4-0.6,0.8
        v0.8c0.1,0.8,0.4,1.4,0.8,2C81.7,30.1,82.6,30.6,83.7,30.7z"
                      />
                      <path
                        className="st0"
                        d="M104.6,17.1h8v12.6l0.2,0.5c0.2,0.3,0.5,0.4,0.8,0.5c0.3,0,0.7-0.1,0.9-0.4l0.3-0.5V17.1h8.1l0.6-0.3
        c0.2-0.2,0.4-0.5,0.4-0.8c0-0.7-0.4-1.1-1.1-1.2h-18.3c-0.5,0-0.8,0.3-1.1,0.9v0.6C103.8,16.6,104.1,16.9,104.6,17.1z"
                      />
                      <path
                        className="st0"
                        d="M127.6,16.7l0.7,0.3h18.4l0.6-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.1-0.3,0.1-0.5c0-0.7-0.4-1.1-1.1-1.2
        h-18.3c-0.5,0.1-0.8,0.4-1.1,0.9c0,0.2,0,0.5,0,0.6C127.4,16.4,127.5,16.6,127.6,16.7z"
                      />
                      <path
                        className="st0"
                        d="M147.8,29.5c0-0.7-0.4-1.2-1-1.3h-17.1v-4.8h15.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.4-0.2l0.3-0.5
        c0-0.2,0-0.4,0-0.6c-0.1-0.2-0.2-0.3-0.3-0.5L145,21h-16.6c-0.5,0.1-0.8,0.4-1,0.9v7.8l0.2,0.4c0.1,0.2,0.3,0.3,0.4,0.3
        c0.1,0.1,0.3,0.1,0.4,0.2h18.3C147.3,30.5,147.7,30.2,147.8,29.5z"
                      />
                      <path
                        className="st0"
                        d="M153.3,18.6c0.1-0.3,0.3-0.6,0.5-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.2-0.1,0.4-0.2,0.7-0.2h12.7l0.8,0.4
        c0.3,0.2,0.5,0.6,0.4,1c0,0.4,0.1,0.7,0.3,1l0.6,0.4l0.7-0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.3-0.5c0-0.5,0-1-0.1-1.5
        c-0.1-0.5-0.3-0.9-0.7-1.3c-0.4-0.5-0.9-0.8-1.4-1c-0.5-0.2-1-0.3-1.6-0.4h-12.6c-0.5,0-1,0.1-1.5,0.3c-0.5,0.2-0.9,0.5-1.3,0.8
        c-0.5,0.5-0.8,1-1.1,1.6c-0.2,0.6-0.4,1.2-0.5,1.8v7.7c0.1,0.4,0.2,0.9,0.4,1.3c0.2,0.4,0.4,0.8,0.7,1.2c0.4,0.4,0.9,0.8,1.5,1
        c0.6,0.2,1.1,0.3,1.7,0.4h12.6c0.4,0,0.8-0.1,1.1-0.2c0.4-0.1,0.7-0.3,1-0.5c0.5-0.4,0.8-0.8,1-1.3c0.2-0.5,0.3-1,0.4-1.5
        l-0.1-0.9c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2,0-0.5,0-0.6,0c-0.2,0.1-0.4,0.2-0.5,0.3
        c-0.2,0.3-0.4,0.6-0.4,1c0,0.4-0.1,0.7-0.2,1l-0.8,0.5H155c-0.2,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.4-0.2-0.6-0.4
        c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.2-0.1-0.5-0.2-0.7v-6.8C153.1,19.2,153.2,18.9,153.3,18.6z"
                      />
                      <path
                        className="st0"
                        d="M17.8,42.6h-1.9v6.6H18c2.1,0,3.2-1.2,3.2-3.3c0-1.1-0.3-1.9-0.8-2.5C19.8,42.9,18.9,42.6,17.8,42.6z
         M16.8,43.4h1c0.8,0,1.5,0.2,1.9,0.6c0.4,0.4,0.6,1,0.6,1.9c0,0.9-0.2,1.5-0.6,1.9c-0.4,0.4-0.9,0.6-1.7,0.6h-1.2V43.4z"
                      />
                      <polygon
                        className="st0"
                        points="23.4,46.2 26.6,46.2 26.6,45.4 23.4,45.4 23.4,43.4 27,43.4 27,42.6 22.6,42.6 22.6,49.3 27.1,49.3 
        27.1,48.4 23.4,48.4 		"
                      />
                      <path
                        className="st0"
                        d="M31.2,45.5l-0.8-0.2c-0.6-0.1-0.9-0.3-1.1-0.4c-0.2-0.1-0.2-0.3-0.2-0.6c0-0.3,0.1-0.5,0.4-0.7
        c0.3-0.2,0.6-0.3,1.1-0.3c1.3,0,1.6,0.6,1.6,1l0,0.2l0.9-0.1l0-0.3c-0.1-0.6-0.3-1-0.8-1.3c-0.4-0.3-1-0.4-1.7-0.4
        c-0.7,0-1.3,0.2-1.7,0.5c-0.4,0.3-0.7,0.8-0.7,1.4c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.8,0.5,1.5,0.6l0.8,0.2
        c0.5,0.1,0.9,0.3,1.1,0.4c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.6-0.4,0.8c-0.3,0.2-0.7,0.3-1.2,0.3c-0.6,0-1-0.1-1.3-0.3
        c-0.3-0.2-0.5-0.5-0.5-0.9l0-0.2l-0.9,0.1l0,0.3c0.1,0.6,0.4,1.1,0.8,1.5c0.5,0.3,1.1,0.5,1.8,0.5c0.8,0,1.4-0.2,1.9-0.5
        c0.5-0.4,0.7-0.9,0.7-1.5c0-0.5-0.2-0.9-0.5-1.3C32.3,45.8,31.9,45.6,31.2,45.5z"
                      />
                      <rect
                        x="34.5"
                        y="42.6"
                        className="st0"
                        width="0.9"
                        height="6.6"
                      />
                      <path
                        className="st0"
                        d="M39.8,43.3c0.9,0,1.5,0.4,1.9,1.3l0.1,0.2l0.9-0.2l-0.1-0.3c-0.2-0.6-0.5-1.1-1-1.4c-0.5-0.3-1-0.5-1.7-0.5
        c-0.9,0-1.7,0.3-2.2,0.9c-0.5,0.6-0.8,1.5-0.8,2.5c0,1,0.3,1.8,0.8,2.5c0.5,0.7,1.3,1,2.2,1c0.9,0,1.5-0.3,2-0.8l0,0.7h0.8v-3.5
        h-2.9v0.8l2,0l0,0.4c-0.1,0.5-0.3,0.9-0.6,1.2c-0.3,0.3-0.7,0.4-1.3,0.4c-0.6,0-1.1-0.2-1.5-0.7c-0.4-0.5-0.6-1.1-0.6-1.9
        c0-0.8,0.2-1.5,0.6-2C38.7,43.5,39.2,43.3,39.8,43.3z"
                      />
                      <polygon
                        className="st0"
                        points="48.8,47.8 45.2,42.6 44.8,42.6 44.7,42.6 44.3,42.6 44.3,49.3 45.2,49.3 45.2,44.1 48.8,49.3 
        49.3,49.3 49.3,49.3 49.7,49.3 49.7,42.6 48.8,42.6 		"
                      />
                      <path
                        className="st0"
                        d="M56.5,42.6h-0.2H56l-2.6,6.6h0.9l0.7-1.8h2.8l0.7,1.8h1l-2.6-6.6H56.5z M55.4,46.6l1.1-2.7l1.1,2.7H55.4z"
                      />
                      <polygon
                        className="st0"
                        points="64.8,47.8 61.2,42.6 60.8,42.6 60.7,42.6 60.3,42.6 60.3,49.3 61.2,49.3 61.2,44.1 64.8,49.3 
        65.3,49.3 65.3,49.3 65.7,49.3 65.7,42.6 64.8,42.6 		"
                      />
                      <path
                        className="st0"
                        d="M69.4,42.6h-1.9v6.6h2.1c2.1,0,3.2-1.2,3.2-3.3c0-1.1-0.3-1.9-0.8-2.5C71.3,42.9,70.5,42.6,69.4,42.6z
         M69.6,48.4h-1.2v-5h1c0.8,0,1.5,0.2,1.9,0.6c0.4,0.4,0.6,1,0.6,1.9c0,0.9-0.2,1.5-0.6,1.9C70.9,48.2,70.3,48.4,69.6,48.4z"
                      />
                      <path
                        className="st0"
                        d="M81.7,47.1c-0.1,0.5-0.3,0.8-0.7,1.1c-0.3,0.3-0.8,0.4-1.3,0.4c-0.7,0-1.2-0.2-1.6-0.7
        c-0.4-0.5-0.6-1.1-0.6-2c0-0.8,0.2-1.5,0.6-1.9c0.4-0.4,0.9-0.6,1.6-0.6c0.5,0,0.9,0.1,1.2,0.3c0.3,0.2,0.6,0.5,0.7,1l0.1,0.2
        l0.9-0.1l-0.1-0.3c-0.2-0.6-0.5-1.1-1-1.4c-0.5-0.3-1.1-0.5-1.7-0.5c-0.9,0-1.7,0.3-2.2,0.9c-0.5,0.6-0.8,1.5-0.8,2.5
        c0,1.1,0.3,1.9,0.8,2.5c0.5,0.6,1.3,0.9,2.2,0.9c0.7,0,1.3-0.2,1.8-0.6c0.5-0.4,0.8-0.9,1-1.6l0.1-0.3l-0.9-0.1L81.7,47.1z"
                      />
                      <path
                        className="st0"
                        d="M86.7,42.5c-1,0-1.7,0.3-2.3,1c-0.5,0.6-0.8,1.5-0.8,2.5c0,1,0.3,1.9,0.8,2.5c0.5,0.6,1.3,0.9,2.2,0.9
        c1,0,1.7-0.3,2.3-0.9c0.5-0.6,0.8-1.5,0.8-2.5c0-1-0.3-1.9-0.8-2.5C88.4,42.8,87.6,42.5,86.7,42.5z M86.7,48.5
        c-0.7,0-1.2-0.2-1.6-0.7c-0.4-0.5-0.6-1.1-0.6-1.9c0-0.8,0.2-1.5,0.6-1.9c0.4-0.5,0.9-0.7,1.6-0.7c0.7,0,1.2,0.2,1.6,0.7
        c0.4,0.5,0.6,1.1,0.6,1.9c0,0.8-0.2,1.5-0.6,1.9C87.9,48.3,87.4,48.5,86.7,48.5z"
                      />
                      <polygon
                        className="st0"
                        points="95.7,47.8 92.1,42.6 91.7,42.6 91.6,42.6 91.2,42.6 91.2,49.3 92.1,49.3 92.1,44.1 95.7,49.3 
        96.1,49.3 96.2,49.3 96.6,49.3 96.6,42.6 95.7,42.6 		"
                      />
                      <polygon
                        className="st0"
                        points="97.6,43.4 99.6,43.4 99.6,49.3 100.5,49.3 100.5,43.4 102.5,43.4 102.5,42.6 97.6,42.6 		"
                      />
                      <path
                        className="st0"
                        d="M107.4,46.1c0.2-0.1,0.4-0.2,0.5-0.4c0.3-0.3,0.4-0.7,0.4-1.2c0-0.6-0.2-1-0.6-1.3c-0.4-0.3-0.9-0.5-1.5-0.5
        h-2.9v6.6h0.9v-2.8h1.7c0.3,0,0.6,0.1,0.8,0.2c0.2,0.2,0.3,0.4,0.4,0.8l0.3,1.8h0.9l-0.3-2C108,46.7,107.8,46.3,107.4,46.1z
         M104.4,43.4h1.9c0.4,0,0.7,0.1,0.9,0.3c0.2,0.2,0.3,0.4,0.3,0.8c0,0.4-0.1,0.6-0.3,0.8c-0.2,0.2-0.6,0.3-1.1,0.3h-1.7V43.4z"
                      />
                      <path
                        className="st0"
                        d="M112.2,42.6H112h-0.3l-2.6,6.6h0.9l0.7-1.8h2.8l0.7,1.8h1l-2.6-6.6H112.2z M111,46.6l1.1-2.7l1.1,2.7H111z"
                      />
                      <path
                        className="st0"
                        d="M120.4,47.1c-0.1,0.5-0.3,0.8-0.7,1.1c-0.3,0.3-0.8,0.4-1.3,0.4c-0.7,0-1.2-0.2-1.6-0.7
        c-0.4-0.5-0.6-1.1-0.6-2c0-0.8,0.2-1.5,0.6-1.9c0.4-0.4,0.9-0.6,1.6-0.6c0.5,0,0.9,0.1,1.2,0.3c0.3,0.2,0.6,0.5,0.7,1l0.1,0.2
        l0.9-0.1l-0.1-0.3c-0.2-0.6-0.5-1.1-1-1.4c-0.5-0.3-1.1-0.5-1.7-0.5c-0.9,0-1.7,0.3-2.2,0.9c-0.5,0.6-0.8,1.5-0.8,2.5
        c0,1.1,0.3,1.9,0.8,2.5c0.5,0.6,1.3,0.9,2.2,0.9c0.7,0,1.3-0.2,1.8-0.6c0.5-0.4,0.8-0.9,1-1.6l0.1-0.3l-0.9-0.1L120.4,47.1z"
                      />
                      <polygon
                        className="st0"
                        points="121.7,43.4 123.7,43.4 123.7,49.3 124.6,49.3 124.6,43.4 126.6,43.4 126.6,42.6 121.7,42.6 		"
                      />
                      <rect
                        x="127.6"
                        y="42.6"
                        className="st0"
                        width="0.9"
                        height="6.6"
                      />
                      <polygon
                        className="st0"
                        points="134.7,47.8 131.1,42.6 130.7,42.6 130.6,42.6 130.2,42.6 130.2,49.3 131.1,49.3 131.1,44.1 
        134.7,49.3 135.1,49.3 135.2,49.3 135.6,49.3 135.6,42.6 134.7,42.6 		"
                      />
                      <path
                        className="st0"
                        d="M140,43.3c0.9,0,1.5,0.4,1.9,1.3l0.1,0.2l0.9-0.2l-0.1-0.3c-0.2-0.6-0.5-1.1-1-1.4c-0.5-0.3-1-0.5-1.7-0.5
        c-0.9,0-1.7,0.3-2.2,0.9c-0.5,0.6-0.8,1.5-0.8,2.5c0,1,0.3,1.8,0.8,2.5c0.5,0.7,1.3,1,2.2,1c0.9,0,1.5-0.3,2-0.8l0,0.7h0.8v-3.5
        h-2.9v0.8l2,0l0,0.4c-0.1,0.5-0.3,0.9-0.6,1.2c-0.3,0.3-0.7,0.4-1.3,0.4c-0.6,0-1.1-0.2-1.5-0.7c-0.4-0.5-0.6-1.1-0.6-1.9
        c0-0.8,0.2-1.5,0.6-2C138.9,43.5,139.4,43.3,140,43.3z"
                      />
                      <polygon
                        className="st0"
                        points="148.3,42.6 147.4,42.6 147.4,49.3 151.7,49.3 151.7,48.4 148.3,48.4 		"
                      />
                      <polygon
                        className="st0"
                        points="151.2,43.4 153.2,43.4 153.2,49.3 154.1,49.3 154.1,43.4 156.1,43.4 156.1,42.6 151.2,42.6 		"
                      />
                      <path
                        className="st0"
                        d="M159,42.6h-1.9v6.6h2.1c2.1,0,3.2-1.2,3.2-3.3c0-1.1-0.3-1.9-0.8-2.5C161,42.9,160.1,42.6,159,42.6z
         M159.2,48.4H158v-5h1c0.8,0,1.5,0.2,1.9,0.6c0.4,0.4,0.6,1,0.6,1.9c0,0.9-0.2,1.5-0.6,1.9C160.5,48.2,160,48.4,159.2,48.4z"
                      />
                      <rect
                        x="163.7"
                        y="48.1"
                        className="st0"
                        width="1.2"
                        height="1.2"
                      />
                    </g>
                  </g>
                </svg>
              </Link>
            ) : null
          ) : (
            <Link to="/">
              <svg
                className={isWhite.isLogoWhite ? "logo" : "logo black"}
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 176.4 61.3"
                style={{ enableBackground: "new 0 0 176.4 61.3" }}
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <path
                      className="st0"
                      d="M27,16.1c-0.4-0.5-0.9-0.8-1.4-1c-0.5-0.2-1-0.3-1.6-0.4H11.5c-0.5,0-1,0.1-1.5,0.3c-0.5,0.2-0.9,0.5-1.3,0.8
        c-0.5,0.5-0.8,1-1.1,1.6c-0.2,0.6-0.4,1.2-0.5,1.8v7.7c0.1,0.4,0.2,0.9,0.4,1.3c0.2,0.4,0.4,0.8,0.7,1.2c0.4,0.4,0.9,0.8,1.5,1
        c0.6,0.2,1.1,0.3,1.7,0.4H24c0.4,0,0.8-0.1,1.1-0.2c0.4-0.1,0.7-0.3,1-0.5c0.5-0.4,0.8-0.8,1-1.3c0.2-0.5,0.3-1,0.4-1.5l-0.1-0.9
        c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2,0-0.5,0-0.6,0c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0.3-0.4,0.6-0.4,1
        c0,0.4-0.1,0.7-0.2,1l-0.8,0.5H11.3c-0.2,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.4-0.7
        c-0.1-0.2-0.1-0.5-0.2-0.7v-6.8c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.6,0.5-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.2-0.1,0.4-0.2,0.7-0.2
        h12.7l0.8,0.4c0.3,0.2,0.5,0.6,0.4,1c0,0.4,0.1,0.7,0.3,1l0.6,0.4l0.7-0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.3-0.5
        c0-0.5,0-1-0.1-1.5C27.5,17,27.3,16.5,27,16.1z"
                    />
                    <path
                      className="st0"
                      d="M47.3,14.7H35.9c-1,0.1-2,0.5-2.8,1.1c-0.9,0.9-1.4,2-1.5,3.1v7.4c0.1,1.1,0.5,2,1.2,2.8
        c0.9,0.9,2,1.4,3.2,1.5h11.6c1-0.1,2-0.4,2.8-1.1c1-1,1.6-2.2,1.7-3.6v-6.7c-0.1-1.1-0.5-2.1-1.1-3C49.9,15.4,48.7,14.8,47.3,14.7
        z M49.5,26.2c-0.1,0.6-0.4,1.2-0.9,1.7l-1.1,0.4H35.9c-0.4,0-0.8-0.2-1.2-0.5c-0.5-0.5-0.8-1.1-0.8-1.8v-6.7
        c0.1-0.6,0.3-1.2,0.7-1.7c0.4-0.4,0.8-0.6,1.3-0.6h11.7c0.5,0.1,0.9,0.3,1.3,0.6c0.5,0.4,0.7,1,0.8,1.6V26.2z"
                    />
                    <path
                      className="st0"
                      d="M75.2,14.7c-0.3,0-0.6,0.1-0.9,0.3l-0.4,0.7V27L57.1,14.7c-0.7-0.2-1.1,0.1-1.2,0.9v14.2l0.2,0.4
        c0.2,0.3,0.5,0.4,0.8,0.5l0.6-0.1c0.3-0.2,0.5-0.5,0.7-0.8V18.2l16.6,12.2c0.9,0.3,1.4,0,1.5-0.9V15.8
        C76.2,15.2,75.8,14.8,75.2,14.7z"
                    />
                    <path
                      className="st0"
                      d="M83.7,30.7H96c1.1-0.1,2.1-0.5,3.1-1.1c1-0.9,1.5-2,1.6-3.3v-0.8c-0.1-1-0.4-1.9-1-2.7c-1-1-2.1-1.6-3.5-1.7
        H84.6c-0.6-0.1-1.1-0.3-1.5-0.6c-0.4-0.3-0.6-0.7-0.6-1.2c0-0.5,0.1-0.9,0.4-1.3c0.5-0.5,1-0.8,1.7-0.9H97c0.8,0.2,1.2,0.6,1.2,1
        c0,0.5,0.1,0.8,0.2,1.1c0.1,0.2,0.4,0.5,0.7,0.6c0.4,0,0.7-0.1,1-0.2c0.3-0.2,0.4-0.5,0.5-0.8c0-1.1-0.3-2-1-2.8
        c-0.8-0.7-1.7-1.1-2.7-1.2H84.7c-1.1,0.1-2.2,0.5-3.1,1.1c-0.8,0.7-1.3,1.6-1.4,2.5v1.4c0.1,0.7,0.4,1.4,0.9,2
        c1,1,2.3,1.6,3.7,1.7h11.5c0.5,0.1,0.9,0.3,1.3,0.5c0.5,0.4,0.7,0.9,0.8,1.4v0.9c-0.1,0.4-0.2,0.7-0.4,1.1c-0.5,0.5-1,0.8-1.7,0.9
        H83.7c-0.4-0.1-0.7-0.3-0.9-0.7c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.4-0.3-0.7-0.9-0.9c-0.4,0-0.7,0-0.9,0.1c-0.2,0.1-0.4,0.4-0.6,0.8
        v0.8c0.1,0.8,0.4,1.4,0.8,2C81.7,30.1,82.6,30.6,83.7,30.7z"
                    />
                    <path
                      className="st0"
                      d="M104.6,17.1h8v12.6l0.2,0.5c0.2,0.3,0.5,0.4,0.8,0.5c0.3,0,0.7-0.1,0.9-0.4l0.3-0.5V17.1h8.1l0.6-0.3
        c0.2-0.2,0.4-0.5,0.4-0.8c0-0.7-0.4-1.1-1.1-1.2h-18.3c-0.5,0-0.8,0.3-1.1,0.9v0.6C103.8,16.6,104.1,16.9,104.6,17.1z"
                    />
                    <path
                      className="st0"
                      d="M127.6,16.7l0.7,0.3h18.4l0.6-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.1-0.3,0.1-0.5c0-0.7-0.4-1.1-1.1-1.2
        h-18.3c-0.5,0.1-0.8,0.4-1.1,0.9c0,0.2,0,0.5,0,0.6C127.4,16.4,127.5,16.6,127.6,16.7z"
                    />
                    <path
                      className="st0"
                      d="M147.8,29.5c0-0.7-0.4-1.2-1-1.3h-17.1v-4.8h15.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.4-0.2l0.3-0.5
        c0-0.2,0-0.4,0-0.6c-0.1-0.2-0.2-0.3-0.3-0.5L145,21h-16.6c-0.5,0.1-0.8,0.4-1,0.9v7.8l0.2,0.4c0.1,0.2,0.3,0.3,0.4,0.3
        c0.1,0.1,0.3,0.1,0.4,0.2h18.3C147.3,30.5,147.7,30.2,147.8,29.5z"
                    />
                    <path
                      className="st0"
                      d="M153.3,18.6c0.1-0.3,0.3-0.6,0.5-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.2-0.1,0.4-0.2,0.7-0.2h12.7l0.8,0.4
        c0.3,0.2,0.5,0.6,0.4,1c0,0.4,0.1,0.7,0.3,1l0.6,0.4l0.7-0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.3-0.5c0-0.5,0-1-0.1-1.5
        c-0.1-0.5-0.3-0.9-0.7-1.3c-0.4-0.5-0.9-0.8-1.4-1c-0.5-0.2-1-0.3-1.6-0.4h-12.6c-0.5,0-1,0.1-1.5,0.3c-0.5,0.2-0.9,0.5-1.3,0.8
        c-0.5,0.5-0.8,1-1.1,1.6c-0.2,0.6-0.4,1.2-0.5,1.8v7.7c0.1,0.4,0.2,0.9,0.4,1.3c0.2,0.4,0.4,0.8,0.7,1.2c0.4,0.4,0.9,0.8,1.5,1
        c0.6,0.2,1.1,0.3,1.7,0.4h12.6c0.4,0,0.8-0.1,1.1-0.2c0.4-0.1,0.7-0.3,1-0.5c0.5-0.4,0.8-0.8,1-1.3c0.2-0.5,0.3-1,0.4-1.5
        l-0.1-0.9c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2,0-0.5,0-0.6,0c-0.2,0.1-0.4,0.2-0.5,0.3
        c-0.2,0.3-0.4,0.6-0.4,1c0,0.4-0.1,0.7-0.2,1l-0.8,0.5H155c-0.2,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.4-0.2-0.6-0.4
        c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.2-0.1-0.5-0.2-0.7v-6.8C153.1,19.2,153.2,18.9,153.3,18.6z"
                    />
                    <path
                      className="st0"
                      d="M17.8,42.6h-1.9v6.6H18c2.1,0,3.2-1.2,3.2-3.3c0-1.1-0.3-1.9-0.8-2.5C19.8,42.9,18.9,42.6,17.8,42.6z
         M16.8,43.4h1c0.8,0,1.5,0.2,1.9,0.6c0.4,0.4,0.6,1,0.6,1.9c0,0.9-0.2,1.5-0.6,1.9c-0.4,0.4-0.9,0.6-1.7,0.6h-1.2V43.4z"
                    />
                    <polygon
                      className="st0"
                      points="23.4,46.2 26.6,46.2 26.6,45.4 23.4,45.4 23.4,43.4 27,43.4 27,42.6 22.6,42.6 22.6,49.3 27.1,49.3 
        27.1,48.4 23.4,48.4 		"
                    />
                    <path
                      className="st0"
                      d="M31.2,45.5l-0.8-0.2c-0.6-0.1-0.9-0.3-1.1-0.4c-0.2-0.1-0.2-0.3-0.2-0.6c0-0.3,0.1-0.5,0.4-0.7
        c0.3-0.2,0.6-0.3,1.1-0.3c1.3,0,1.6,0.6,1.6,1l0,0.2l0.9-0.1l0-0.3c-0.1-0.6-0.3-1-0.8-1.3c-0.4-0.3-1-0.4-1.7-0.4
        c-0.7,0-1.3,0.2-1.7,0.5c-0.4,0.3-0.7,0.8-0.7,1.4c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.8,0.5,1.5,0.6l0.8,0.2
        c0.5,0.1,0.9,0.3,1.1,0.4c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.6-0.4,0.8c-0.3,0.2-0.7,0.3-1.2,0.3c-0.6,0-1-0.1-1.3-0.3
        c-0.3-0.2-0.5-0.5-0.5-0.9l0-0.2l-0.9,0.1l0,0.3c0.1,0.6,0.4,1.1,0.8,1.5c0.5,0.3,1.1,0.5,1.8,0.5c0.8,0,1.4-0.2,1.9-0.5
        c0.5-0.4,0.7-0.9,0.7-1.5c0-0.5-0.2-0.9-0.5-1.3C32.3,45.8,31.9,45.6,31.2,45.5z"
                    />
                    <rect
                      x="34.5"
                      y="42.6"
                      className="st0"
                      width="0.9"
                      height="6.6"
                    />
                    <path
                      className="st0"
                      d="M39.8,43.3c0.9,0,1.5,0.4,1.9,1.3l0.1,0.2l0.9-0.2l-0.1-0.3c-0.2-0.6-0.5-1.1-1-1.4c-0.5-0.3-1-0.5-1.7-0.5
        c-0.9,0-1.7,0.3-2.2,0.9c-0.5,0.6-0.8,1.5-0.8,2.5c0,1,0.3,1.8,0.8,2.5c0.5,0.7,1.3,1,2.2,1c0.9,0,1.5-0.3,2-0.8l0,0.7h0.8v-3.5
        h-2.9v0.8l2,0l0,0.4c-0.1,0.5-0.3,0.9-0.6,1.2c-0.3,0.3-0.7,0.4-1.3,0.4c-0.6,0-1.1-0.2-1.5-0.7c-0.4-0.5-0.6-1.1-0.6-1.9
        c0-0.8,0.2-1.5,0.6-2C38.7,43.5,39.2,43.3,39.8,43.3z"
                    />
                    <polygon
                      className="st0"
                      points="48.8,47.8 45.2,42.6 44.8,42.6 44.7,42.6 44.3,42.6 44.3,49.3 45.2,49.3 45.2,44.1 48.8,49.3 
        49.3,49.3 49.3,49.3 49.7,49.3 49.7,42.6 48.8,42.6 		"
                    />
                    <path
                      className="st0"
                      d="M56.5,42.6h-0.2H56l-2.6,6.6h0.9l0.7-1.8h2.8l0.7,1.8h1l-2.6-6.6H56.5z M55.4,46.6l1.1-2.7l1.1,2.7H55.4z"
                    />
                    <polygon
                      className="st0"
                      points="64.8,47.8 61.2,42.6 60.8,42.6 60.7,42.6 60.3,42.6 60.3,49.3 61.2,49.3 61.2,44.1 64.8,49.3 
        65.3,49.3 65.3,49.3 65.7,49.3 65.7,42.6 64.8,42.6 		"
                    />
                    <path
                      className="st0"
                      d="M69.4,42.6h-1.9v6.6h2.1c2.1,0,3.2-1.2,3.2-3.3c0-1.1-0.3-1.9-0.8-2.5C71.3,42.9,70.5,42.6,69.4,42.6z
         M69.6,48.4h-1.2v-5h1c0.8,0,1.5,0.2,1.9,0.6c0.4,0.4,0.6,1,0.6,1.9c0,0.9-0.2,1.5-0.6,1.9C70.9,48.2,70.3,48.4,69.6,48.4z"
                    />
                    <path
                      className="st0"
                      d="M81.7,47.1c-0.1,0.5-0.3,0.8-0.7,1.1c-0.3,0.3-0.8,0.4-1.3,0.4c-0.7,0-1.2-0.2-1.6-0.7
        c-0.4-0.5-0.6-1.1-0.6-2c0-0.8,0.2-1.5,0.6-1.9c0.4-0.4,0.9-0.6,1.6-0.6c0.5,0,0.9,0.1,1.2,0.3c0.3,0.2,0.6,0.5,0.7,1l0.1,0.2
        l0.9-0.1l-0.1-0.3c-0.2-0.6-0.5-1.1-1-1.4c-0.5-0.3-1.1-0.5-1.7-0.5c-0.9,0-1.7,0.3-2.2,0.9c-0.5,0.6-0.8,1.5-0.8,2.5
        c0,1.1,0.3,1.9,0.8,2.5c0.5,0.6,1.3,0.9,2.2,0.9c0.7,0,1.3-0.2,1.8-0.6c0.5-0.4,0.8-0.9,1-1.6l0.1-0.3l-0.9-0.1L81.7,47.1z"
                    />
                    <path
                      className="st0"
                      d="M86.7,42.5c-1,0-1.7,0.3-2.3,1c-0.5,0.6-0.8,1.5-0.8,2.5c0,1,0.3,1.9,0.8,2.5c0.5,0.6,1.3,0.9,2.2,0.9
        c1,0,1.7-0.3,2.3-0.9c0.5-0.6,0.8-1.5,0.8-2.5c0-1-0.3-1.9-0.8-2.5C88.4,42.8,87.6,42.5,86.7,42.5z M86.7,48.5
        c-0.7,0-1.2-0.2-1.6-0.7c-0.4-0.5-0.6-1.1-0.6-1.9c0-0.8,0.2-1.5,0.6-1.9c0.4-0.5,0.9-0.7,1.6-0.7c0.7,0,1.2,0.2,1.6,0.7
        c0.4,0.5,0.6,1.1,0.6,1.9c0,0.8-0.2,1.5-0.6,1.9C87.9,48.3,87.4,48.5,86.7,48.5z"
                    />
                    <polygon
                      className="st0"
                      points="95.7,47.8 92.1,42.6 91.7,42.6 91.6,42.6 91.2,42.6 91.2,49.3 92.1,49.3 92.1,44.1 95.7,49.3 
        96.1,49.3 96.2,49.3 96.6,49.3 96.6,42.6 95.7,42.6 		"
                    />
                    <polygon
                      className="st0"
                      points="97.6,43.4 99.6,43.4 99.6,49.3 100.5,49.3 100.5,43.4 102.5,43.4 102.5,42.6 97.6,42.6 		"
                    />
                    <path
                      className="st0"
                      d="M107.4,46.1c0.2-0.1,0.4-0.2,0.5-0.4c0.3-0.3,0.4-0.7,0.4-1.2c0-0.6-0.2-1-0.6-1.3c-0.4-0.3-0.9-0.5-1.5-0.5
        h-2.9v6.6h0.9v-2.8h1.7c0.3,0,0.6,0.1,0.8,0.2c0.2,0.2,0.3,0.4,0.4,0.8l0.3,1.8h0.9l-0.3-2C108,46.7,107.8,46.3,107.4,46.1z
         M104.4,43.4h1.9c0.4,0,0.7,0.1,0.9,0.3c0.2,0.2,0.3,0.4,0.3,0.8c0,0.4-0.1,0.6-0.3,0.8c-0.2,0.2-0.6,0.3-1.1,0.3h-1.7V43.4z"
                    />
                    <path
                      className="st0"
                      d="M112.2,42.6H112h-0.3l-2.6,6.6h0.9l0.7-1.8h2.8l0.7,1.8h1l-2.6-6.6H112.2z M111,46.6l1.1-2.7l1.1,2.7H111z"
                    />
                    <path
                      className="st0"
                      d="M120.4,47.1c-0.1,0.5-0.3,0.8-0.7,1.1c-0.3,0.3-0.8,0.4-1.3,0.4c-0.7,0-1.2-0.2-1.6-0.7
        c-0.4-0.5-0.6-1.1-0.6-2c0-0.8,0.2-1.5,0.6-1.9c0.4-0.4,0.9-0.6,1.6-0.6c0.5,0,0.9,0.1,1.2,0.3c0.3,0.2,0.6,0.5,0.7,1l0.1,0.2
        l0.9-0.1l-0.1-0.3c-0.2-0.6-0.5-1.1-1-1.4c-0.5-0.3-1.1-0.5-1.7-0.5c-0.9,0-1.7,0.3-2.2,0.9c-0.5,0.6-0.8,1.5-0.8,2.5
        c0,1.1,0.3,1.9,0.8,2.5c0.5,0.6,1.3,0.9,2.2,0.9c0.7,0,1.3-0.2,1.8-0.6c0.5-0.4,0.8-0.9,1-1.6l0.1-0.3l-0.9-0.1L120.4,47.1z"
                    />
                    <polygon
                      className="st0"
                      points="121.7,43.4 123.7,43.4 123.7,49.3 124.6,49.3 124.6,43.4 126.6,43.4 126.6,42.6 121.7,42.6 		"
                    />
                    <rect
                      x="127.6"
                      y="42.6"
                      className="st0"
                      width="0.9"
                      height="6.6"
                    />
                    <polygon
                      className="st0"
                      points="134.7,47.8 131.1,42.6 130.7,42.6 130.6,42.6 130.2,42.6 130.2,49.3 131.1,49.3 131.1,44.1 
        134.7,49.3 135.1,49.3 135.2,49.3 135.6,49.3 135.6,42.6 134.7,42.6 		"
                    />
                    <path
                      className="st0"
                      d="M140,43.3c0.9,0,1.5,0.4,1.9,1.3l0.1,0.2l0.9-0.2l-0.1-0.3c-0.2-0.6-0.5-1.1-1-1.4c-0.5-0.3-1-0.5-1.7-0.5
        c-0.9,0-1.7,0.3-2.2,0.9c-0.5,0.6-0.8,1.5-0.8,2.5c0,1,0.3,1.8,0.8,2.5c0.5,0.7,1.3,1,2.2,1c0.9,0,1.5-0.3,2-0.8l0,0.7h0.8v-3.5
        h-2.9v0.8l2,0l0,0.4c-0.1,0.5-0.3,0.9-0.6,1.2c-0.3,0.3-0.7,0.4-1.3,0.4c-0.6,0-1.1-0.2-1.5-0.7c-0.4-0.5-0.6-1.1-0.6-1.9
        c0-0.8,0.2-1.5,0.6-2C138.9,43.5,139.4,43.3,140,43.3z"
                    />
                    <polygon
                      className="st0"
                      points="148.3,42.6 147.4,42.6 147.4,49.3 151.7,49.3 151.7,48.4 148.3,48.4 		"
                    />
                    <polygon
                      className="st0"
                      points="151.2,43.4 153.2,43.4 153.2,49.3 154.1,49.3 154.1,43.4 156.1,43.4 156.1,42.6 151.2,42.6 		"
                    />
                    <path
                      className="st0"
                      d="M159,42.6h-1.9v6.6h2.1c2.1,0,3.2-1.2,3.2-3.3c0-1.1-0.3-1.9-0.8-2.5C161,42.9,160.1,42.6,159,42.6z
         M159.2,48.4H158v-5h1c0.8,0,1.5,0.2,1.9,0.6c0.4,0.4,0.6,1,0.6,1.9c0,0.9-0.2,1.5-0.6,1.9C160.5,48.2,160,48.4,159.2,48.4z"
                    />
                    <rect
                      x="163.7"
                      y="48.1"
                      className="st0"
                      width="1.2"
                      height="1.2"
                    />
                  </g>
                </g>
              </svg>
            </Link>
          )}
          <button
            className="menu-text nav"
            style={{ color: isWhite.textColor }}
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            Menu
          </button>
          <div
            ref={offcanvasNode}
            className="offcanvas offcanvas-end constec-offcanvas"
            tabIndex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div className="offcanvas-header">
              <button
                type="button"
                className="menu-text"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                Close
              </button>
            </div>
            <div className="offcanvas-body">
              <div className="container">
                <ul>
                  {nav.map((navObj, index) => {
                    return (
                      <li key={index}>
                        <NavLink
                          to={navObj.path}
                          className="nav"
                          onMouseOver={handleMouseover}
                        >
                          <p
                            ref={(c) => nodeListRef.push(c)}
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                          >
                            {navObj.text}
                          </p>
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
                <div className="row social-wrapper">
                  <div className="col-12 col-md-6 col-xl-6 left">
                    <Link to="mailto: Joshua@constec.ca" className="email">
                      Joshua@constec.ca
                    </Link>
                  </div>
                  <div className="col-12 col-md-6 col-xl-6 right">
                    <div className="socials">
                      <Link
                        to="https://www.instagram.com/constecdesigns/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="instagram"
                      >
                        Instagram
                      </Link>
                      <Link
                        to="https://www.facebook.com/constecdesign/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="facebook"
                      >
                        Facebook
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row copyright-wrapper">
                  <div className="col-12 col-md-6 col-xl-6 left">
                    <Link to="/privacy-legal">
                      <div
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                      >
                        Privacy & Legal
                      </div>
                    </Link>
                  </div>
                  <div className="col-12 col-md-6 col-xl-6 right copyright">
                    © 2024 Constec Design & Contracting Ltd. All rights
                    reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
