import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ReactLenis } from "@studio-freight/react-lenis";
import "./style.css";

gsap.registerPlugin(ScrollTrigger);

const Privacy = () => {
  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <ReactLenis root options={{ smoothTouch: true }}>
      <main className="privacyPage ppadding">
        <section className="bnr-privacy global-padding">
          <div className="heading-wrapper">
            <h1>Privacy Policy</h1>
            <div>
              <h2>Legal Disclaimer</h2>
              <p>
                The Contractor reserves the right to make modifications or
                substitutions to project design, specifications, features,
                appliances, pricing and floor plans in its sole discretion and
                without notice or obligation. Dimensions, square footage and
                floor plans are illustrative, approximate and provided for
                reference only. Final dimensions, square footage and floor plans
                may vary and will be determined by final strata plan
                measurements. This is not an offering for sale. Any such
                offering may only be made with a disclosure statement.
              </p>
              <h2>Privacy Policy</h2>
              <p>
                Transparency is one of Constec Design & Contracting core values.
                The information we will require from you, how this information
                will be handled, and how this information will be kept safe is
                described in detail as follows. The purpose of this policy is to
                inform you of our information handling practices; the reasons
                for these practices; security steps in place to protect your
                information; and your role in controlling your own personal
                information. We collect personal information to better serve
                your needs. In order to provide you with our wide variety of
                services, products, and promotions, we may collect the following
                information when necessary:
              </p>
              <p>
                • Contact and mailing information such as name, address,
                telephone numbers, fax nu mbers and email addresses.
                <br />
                • Credit information, and personal information.
                <br />• Any other relevant information which is necessary to
                provide you with our products and services.{" "}
              </p>
              <p>
                We may need to collect other personal information depending on
                the circumstances, but we will ask you directly and ensure that
                you agree.
              </p>
              <p>
                Part of valuing your personal information is making sure that it
                is protected and kept confidential. We achieve this by keeping
                our records secure and having up to date computer security such
                as passwords, encryption and firewalls.
              </p>
              <p>
                We collect information about you on a voluntary basis. If you
                have questions or concerns about the handling of your personal
                information or wish to withdraw your consent, please contact
                Constec Design & Contracting. Our representatives would be happy
                to assist you.
              </p>
              <p>
                Constec Design & Contracting provides you with the opportunity
                to access your personal information that is held by us. If you
                require another copy of such information or further information,
                please contact Constec Design & Contracting.
              </p>
              <p>
                Constec Design & Contracting will do its best to ensure that
                your personal information is correct and kept current. A
                customer service representative would contact you to verify your
                personal information before any decisions are made or action is
                taken based on that information. We will keep your information
                as long as is necessary to continue to meet your customer needs
                and any legal requirements we may have. However, once your
                personal information is no longer needed, we will destroy it. If
                your personal information changes, please inform us of the
                change and any other information you feel is necessary. Also, if
                your personal information is incorrect, then please notify us of
                the correct information
              </p>
            </div>
          </div>
        </section>
      </main>
    </ReactLenis>
  );
};

export default Privacy;
