import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Heading from "../../components/common/Heading";
import ProjectsFlickity from "../../components/common/ProjectsFlickity";
import Intro from "../../components/common/Intro";
import ProcessAccordion from "./ProcessAccordion";
import { hero } from "../../components/Data";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ReactLenis } from "@studio-freight/react-lenis";
import "./style.css";

gsap.registerPlugin(ScrollTrigger);

const Homepage = () => {
  const [matches, setMatches] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      window.scrollTo(0, 0);
      setMatches(true)
    }

    let ctx = gsap.context(() => {

      // pin banner
      ScrollTrigger.create({
        trigger: ".bg-bnr",
        start: "top top",
        pin: true,
        pinSpacing: false,
      });

      // imgAnimate
      // let imgAnimates = gsap.utils.toArray(".imgAnimate");
      // imgAnimates.forEach((imgAnimate) => {
        gsap.from('.imgAnimate1', {
          autoAlpha: 0,
          duration: 1,
          scrollTrigger: {
            trigger: '.imgAnimate1',
            start: "top 80%",
            //end: "top -100%",
            end: "bottom",
            toggleActions: "play reverse play reverse",
            // markers:true
          },
        });
      // });
        gsap.from('.imgAnimate2', {
          autoAlpha: 0,
          duration: 1,
          scrollTrigger: {
            trigger: '.imgAnimate2',
            start: "top 80%",
            //end: "top -100%",
            end: "bottom",
            toggleActions: "play reverse play reverse",
            // markers:true
          },
        });

      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 100);
    });

    return () => ctx.revert();
  }, []);

  // const [someState, setSomeState] = useState(() => {
  //   // Initialize state from localStorage if available
  //   const savedState = localStorage.getItem('someState');
  //   return savedState ? JSON.parse(savedState) : 'initialValue';
  // });

  // useEffect(() => {
  //   // Save state to localStorage on state change
  //   localStorage.setItem('someState', JSON.stringify(someState));
  // }, [someState]);

  // useEffect(() => {
  //   // Check if the page has been reloaded already
  //   const hasReloaded = sessionStorage.getItem('hasReloaded');

  //   if (!hasReloaded) {
  //     // Set the flag to indicate the page has been reloaded
  //     sessionStorage.setItem('hasReloaded', 'true');
  //     // Reload the page
  //     window.location.reload();
  //   } else {
  //     // Clear the flag after the page reloads
  //     sessionStorage.removeItem('hasReloaded');
  //   }
  // }, []);

  return (
    <ReactLenis root options={{ smoothTouch: true }}>
      <main className="home">
        <section className="bg-bnr">
          <div className="is-relative">
            <div className="bg-video-container">
              <video
                className="bg-video"
                poster={hero[0].path}
                preload="metadata"
                autoPlay
                loop
                muted
                playsInline
              >
                <source src="/videos/Everyoung.mp4" type="video/mp4" />
              </video>
            </div>
            {
              matches && location.pathname === "/" ? 
              <img className="mobileBnr-Logo" src="../images/logo/logo-mobile.png" alt="logo" /> : null
            }
          </div>
        </section>
        <div
          style={{
            position: "relative",
            zIndex: 2,
            overflow: "hidden",
            background: "#FFFEFA",
          }}
        >
          <section className="home-intro ppadding">
            <div className="global-padding">
              <Heading
                title={[
                  "We’re a pioneering commercial design build company, revolutionizing workspaces for the future. We specialize in providing comprehensive design-build services for enhancing business interiors through tenant improvement construction.",
                ]}
              />
            </div>
            <div className="projects-carousel">
              <h2>Our Projects</h2>
              <ProjectsFlickity />
            </div>
            <div className="global-padding projectsIntro">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6 left">
                    <Intro
                      contents="Irrespective of the project, our team consistently endeavors to pioneer new frontiers in crafting the workspaces of tomorrow. We specialize in designing interiors that enhance both employee productivity and client satisfaction. Every aspect, from space planning to design to construction to quality control, is meticulously scrutinized to ensure optimal outcomes."
                      sub="View all projects"
                      path="/projects"
                    />
                  </div>
                  <div className="col-12 col-md-6 right">
                    <img
                      className="imgAnimate1"
                      src="../images/home/projectsIntro.jpg"
                      alt="project"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="expertiseServices ppadding">
            <div className="global-padding">
              <h2>Expertise & Services</h2>
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6 left">
                    <Intro
                      contents="At Constec, we assume full project control, managing all subcontractors for you. Whether it’s expediting permit approvals with city authorities or obtaining essential paperwork for final occupancy, our team has successfully navigated multiple municipalities, addressing any potential city-related issues along the way."
                      sub="View our process"
                      path="/company"
                    />
                  </div>
                  <div className="col-12 col-md-6 right">
                    <ProcessAccordion />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 left">
                    <img
                      className="imgAnimate2"
                      src="../images/home/aboutusIntro.jpg"
                      alt="About Us"
                    />
                  </div>
                  <div className="col-12 col-md-6 right">
                    <Intro
                      contents="Constec Design & Contracting was founded with a singular mission: to deliver comprehensive interior fit-outs that cultivate dynamic and captivating work environments for businesses."
                      sub="About us"
                      path="/company"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="global-padding contact-section ppadding">
            <h2>Connect with us</h2>
            <Intro
              contents="We guarantee that your environment mirrors a dedication to excellence, well-being, and the values of your projects. Reach out to us to synchronize visions and fashion a space that endures the test of time."
              sub="Contact us"
              path="/contact"
            />
          </section>
        </div>
      </main>
    </ReactLenis>
  );
};

export default Homepage;
