import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { projects } from "../../../components/Data";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ReactLenis } from "@studio-freight/react-lenis";
import "./style.css";

gsap.registerPlugin(ScrollTrigger);

const ProjectsList = () => {
  const location = useLocation();
  const triggerRef = useRef(null);
  useEffect(() => {
    let ctx = gsap.context(() => {

      gsap.from(".grid-container", {
        autoAlpha: 0,
        duration: 1,
        scrollTrigger: {
          trigger: ".grid-container",
          start: "10% 100%",
          // end: "bottom -100%",
          end: "bottom",
          toggleActions: "play reverse play reverse",
          // markers:true
        },
      }, triggerRef);
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 100);
    });

    return () => ctx.revert();
  }, [location.pathname]);
  return (
    <ReactLenis root options={{ smoothTouch: true }}>
      <div className="grid-container" ref={triggerRef}>
        {projects.map((project, index) => {
          return (
            <div className="project-wrapper is-relative" key={index}>
              <Link to={`/projects-detail/${project.id}`} className="">
                <img
                  className="projectList-img"
                  src={project.diffSizePath}
                  alt={project.heading}
                />
              </Link>
              <div className="show-details">
                <h4>{project.heading}</h4>
                <p>{project.location}</p>
              </div>
            </div>
          );
        })}
      </div>
    </ReactLenis>
  );
};

export default ProjectsList;
