import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ReactLenis } from "@studio-freight/react-lenis";
import "./style.css";

const Banner = ({ imgPath, heading }) => {
  const bnrRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      const bnr = bnrRef.current;

      ScrollTrigger.create({
        trigger: bnr,
        start: "top top",
        pin: true,
        pinSpacing: false,
      });
      
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 100);
    });

    return () => ctx.revert();
  }, []);

  return (
    <ReactLenis root options={{ smoothTouch: true }}>
      <section
        ref={bnrRef}
        className="bnr"
        style={{
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(${imgPath})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div>{heading}</div>
      </section>
    </ReactLenis>
  );
};

export default Banner;
