export const nav = [
  {
    text: "Home",
    path: "/",
  },
  {
    text: "Projects",
    path: "/projects",
  },
  {
    text: "Company",
    path: "/company",
  },
  {
    text: "Contact",
    path: "/contact",
  },
];

export const hero = [
  {
    path: "../images/hero/home-bnr.jpg",
    alt: "Home Banner",
  },
  {
    path: "../images/hero/residences-bnr.jpg",
    alt: "Residences Banner",
  },
  {
    path: "../images/hero/floorplan-bnr.jpg",
    alt: "Floorplan Banner",
  },
  {
    path: "../images/hero/lifestyle-bnr.jpg",
    alt: "Lifestyle Banner",
  },
  {
    path: "../images/hero/gallery-bnr.jpg",
    alt: "Gallery Banner",
  },
  {
    path: "../images/hero/team-bnr.jpg",
    alt: "Team Banner",
  },
];

export const projects = [
  {
    id: 0,
    path: "../images/projects/project-1.jpg",
    diffSizePath:"../images/projects/diffSize/project-1.jpg",
    pathL: "../images/projects/large/project-1.jpg",
    heading: "EverYoung Laser & Skin Clinic",
    location: "Downtown Vancouver, BC",
    detail:
      "Spotlighting our recent tenant improvement endeavor in the vibrant heart of Downtown Vancouver: a meticulously crafted laser and skin clinic. Constec Design & Contracting undertook the design and construction for this project. The client’s specifications included dedicated spaces for laser treatments and skincare space. Despite numerous challenges, including city regulations and strata guidelines, we surpassed expectations with ingenuity and determination.",
    introThumb: "../images/projects/introThumbs/project-1.jpg",
    thumbnails: [
      "../images/projects/thumbnails/project1/gallery-1.jpg",
      "../images/projects/thumbnails/project1/1.jpg",
      "../images/projects/thumbnails/project1/2.jpg",
      "../images/projects/thumbnails/project1/3.jpg",
      "../images/projects/thumbnails/project1/4.jpg",
      "../images/projects/thumbnails/project1/5.jpg",
      "../images/projects/thumbnails/project1/6.jpg",
      "../images/projects/thumbnails/project1/7.jpg",
      "../images/projects/thumbnails/project1/8.jpg",
      "../images/projects/thumbnails/project1/9.jpg",
      "../images/projects/thumbnails/project1/10.jpg",
      "../images/projects/thumbnails/project1/11.jpg",
      "../images/projects/thumbnails/project1/12.jpg",
      "../images/projects/thumbnails/project1/13.jpg",
      "../images/projects/thumbnails/project1/14.jpg",
      "../images/projects/thumbnails/project1/15.jpg",
      "../images/projects/thumbnails/project1/16.jpg",
      "../images/projects/thumbnails/project1/17.jpg",
      "../images/projects/thumbnails/project1/18.jpg",
      "../images/projects/thumbnails/project1/19.jpg",
      "../images/projects/thumbnails/project1/20.jpg",
    ],
    videoPath: [
      {
        path: "/images/projects/videos/project-1.mp4",
        poster: "../images/projects/videoPoster/project-1.jpg",
      },
    ],
  },
  {
    id: 1,
    path: "../images/projects/project-2.jpg",
    diffSizePath:"../images/projects/diffSize/project-2.jpg",
    pathL: "../images/projects/large/project-2.jpg",
    heading: "Breka Bakery & Café",
    location: "Downtown Vancouver, BC",
    detail: "",
    introThumb: "../images/projects/introThumbs/project-2.jpg",
    thumbnails: [
      "../images/projects/thumbnails/project2/gallery-1.jpg",
      "../images/projects/thumbnails/project2/1.jpg",
      "../images/projects/thumbnails/project2/2.jpg",
      "../images/projects/thumbnails/project2/3.jpg",
      "../images/projects/thumbnails/project2/4.jpg",
      "../images/projects/thumbnails/project2/5.jpg",
      "../images/projects/thumbnails/project2/6.jpg",
      "../images/projects/thumbnails/project2/7.jpg",
      "../images/projects/thumbnails/project2/8.jpg",
      "../images/projects/thumbnails/project2/9.jpg",
      "../images/projects/thumbnails/project2/10.jpg",
      "../images/projects/thumbnails/project2/11.jpg",
      "../images/projects/thumbnails/project2/12.jpg",
      "../images/projects/thumbnails/project2/13.jpg",
      "../images/projects/thumbnails/project2/14.jpg",
      "../images/projects/thumbnails/project2/15.jpg",
      "../images/projects/thumbnails/project2/16.jpg",
      "../images/projects/thumbnails/project2/17.jpg",
      "../images/projects/thumbnails/project2/18.jpg",
    ],
    videoPath: [
      {
        path: "/images/projects/videos/project-2.mp4",
        poster: "../images/projects/videoPoster/project-2.jpg",
      },
    ],
  },
  {
    id: 2,
    path: "../images/projects/project-3.jpg",
    diffSizePath:"../images/projects/diffSize/project-3.jpg",
    pathL: "../images/projects/large/project-3.jpg",
    heading: "APM Monaco (Pacific Center)",
    location: "Downtown Vancouver, BC",
    detail: "",
    introThumb: "../images/projects/introThumbs/project-3.jpg",
    thumbnails: [
      "../images/projects/thumbnails/project3/gallery-1.jpg",
      "../images/projects/thumbnails/project3/1.jpg",
      "../images/projects/thumbnails/project3/2.jpg",
      "../images/projects/thumbnails/project3/3.jpg",
      "../images/projects/thumbnails/project3/4.jpg",
      "../images/projects/thumbnails/project3/5.jpg",
      "../images/projects/thumbnails/project3/6.jpg",
      "../images/projects/thumbnails/project3/7.jpg",
      "../images/projects/thumbnails/project3/8.jpg",
      "../images/projects/thumbnails/project3/9.jpg",
      "../images/projects/thumbnails/project3/10.jpg",
      "../images/projects/thumbnails/project3/11.jpg",
      "../images/projects/thumbnails/project3/12.jpg",
      "../images/projects/thumbnails/project3/13.jpg",
      "../images/projects/thumbnails/project3/14.jpg",
      "../images/projects/thumbnails/project3/15.jpg",
      "../images/projects/thumbnails/project3/16.jpg",
    ],
    videoPath: [
      {
        path: "/images/projects/videos/project-3.mp4",
        poster: "../images/projects/videoPoster/project-3.jpg",
      },],
  },
  {
    id: 3,
    path: "../images/projects/project-4.jpg",
    diffSizePath:"../images/projects/diffSize/project-4.jpg",
    pathL: "../images/projects/large/project-4.jpg",
    heading: "618 Carnarvon Presentation Centre",
    location: "New Westminster, BC",
    detail: "",
    introThumb: "../images/projects/introThumbs/project-4.jpg",
    thumbnails: [
      "../images/projects/thumbnails/project4/gallery-1.jpg",
      "../images/projects/thumbnails/project4/1.jpg",
      "../images/projects/thumbnails/project4/2.jpg",
      "../images/projects/thumbnails/project4/3.jpg",
      "../images/projects/thumbnails/project4/4.jpg",
      "../images/projects/thumbnails/project4/5.jpg",
      "../images/projects/thumbnails/project4/6.jpg",
      "../images/projects/thumbnails/project4/7.jpg",
      "../images/projects/thumbnails/project4/8.jpg",
      "../images/projects/thumbnails/project4/9.jpg",
      "../images/projects/thumbnails/project4/10.jpg",
      "../images/projects/thumbnails/project4/11.jpg",
      "../images/projects/thumbnails/project4/12.jpg",
      "../images/projects/thumbnails/project4/13.jpg",
    ],
    videoPath: [
      {
        path: "/images/projects/videos/project-4-1.mp4",
        poster: "../images/projects/videoPoster/project-4.jpg",
      },
      {
        path: "/images/projects/videos/project-4-2.mp4",
        poster: "../images/projects/videoPoster/project-4.jpg",
      },
    ],
  },
  {
    id: 4,
    path: "../images/projects/project-5.jpg",
    diffSizePath:"../images/projects/diffSize/project-5.jpg",
    pathL: "../images/projects/large/project-5.jpg",
    heading: "APM Monaco (CF Richmond Centre)",
    location: "Richmond, BC",
    detail: "",
    introThumb: "../images/projects/introThumbs/project-5.jpg",
    thumbnails: [
      "../images/projects/thumbnails/project5/gallery-1.jpg",
      "../images/projects/thumbnails/project5/1.jpg",
      "../images/projects/thumbnails/project5/2.jpg",
      "../images/projects/thumbnails/project5/3.jpg",
      "../images/projects/thumbnails/project5/4.jpg",
      "../images/projects/thumbnails/project5/5.jpg",
      "../images/projects/thumbnails/project5/6.jpg",
      "../images/projects/thumbnails/project5/7.jpg",
      "../images/projects/thumbnails/project5/8.jpg",
      "../images/projects/thumbnails/project5/9.jpg",
      "../images/projects/thumbnails/project5/10.jpg",
      "../images/projects/thumbnails/project5/11.jpg",
      "../images/projects/thumbnails/project5/12.jpg",
      "../images/projects/thumbnails/project5/13.jpg",
      "../images/projects/thumbnails/project5/14.jpg",
      "../images/projects/thumbnails/project5/15.jpg",
      "../images/projects/thumbnails/project5/16.jpg",
    ],
    videoPath: [],
  },
  {
    id: 5,
    path: "../images/projects/project-6.jpg",
    diffSizePath:"../images/projects/diffSize/project-6.jpg",
    pathL: "../images/projects/large/project-6.jpg",
    heading: "Amber Financial",
    location: "Richmond, BC",
    detail: "",
    introThumb: "../images/projects/introThumbs/project-6.jpg",
    thumbnails: [
      "../images/projects/thumbnails/project6/gallery-1.jpg",
      "../images/projects/thumbnails/project6/1.jpg",
      "../images/projects/thumbnails/project6/2.jpg",
      "../images/projects/thumbnails/project6/3.jpg",
      "../images/projects/thumbnails/project6/4.jpg",
      "../images/projects/thumbnails/project6/5.jpg",
      "../images/projects/thumbnails/project6/6.jpg",
      "../images/projects/thumbnails/project6/7.jpg",
      "../images/projects/thumbnails/project6/8.jpg",
    ],
    videoPath: [],
  },
  {
    id: 6,
    path: "../images/projects/project-7.jpg",
    diffSizePath:"../images/projects/diffSize/project-7.jpg",
    pathL: "../images/projects/large/project-7.jpg",
    heading: "Residential Home (633 Kinghorne Mews)",
    location: "Vancouver, BC",
    detail: "",
    introThumb: "../images/projects/introThumbs/project-7.jpg",
    thumbnails: [
      "../images/projects/thumbnails/project7/gallery-1.jpg",
      "../images/projects/thumbnails/project7/1.jpg",
      "../images/projects/thumbnails/project7/2.jpg",
      "../images/projects/thumbnails/project7/3.jpg",
      "../images/projects/thumbnails/project7/4.jpg",
      "../images/projects/thumbnails/project7/5.jpg",
      "../images/projects/thumbnails/project7/6.jpg",
      "../images/projects/thumbnails/project7/7.jpg",
    ],
    videoPath: [],
  },
  {
    id: 7,
    path: "../images/projects/project-8.jpg",
    diffSizePath:"../images/projects/diffSize/project-8.jpg",
    pathL: "../images/projects/large/project-8.jpg",
    heading: "Residential Home (633 Kinghorne Mews)",
    location: "Vancouver, BC",
    detail: "",
    introThumb: "../images/projects/introThumbs/project-8.jpg",
    thumbnails: [
      "../images/projects/thumbnails/project8/gallery-1.jpg",
      "../images/projects/thumbnails/project8/1.jpg",
      "../images/projects/thumbnails/project8/2.jpg",
      "../images/projects/thumbnails/project8/3.jpg",
      "../images/projects/thumbnails/project8/4.jpg",
      "../images/projects/thumbnails/project8/5.jpg",
      "../images/projects/thumbnails/project8/6.jpg",
      "../images/projects/thumbnails/project8/7.jpg",
      "../images/projects/thumbnails/project8/8.jpg",
      "../images/projects/thumbnails/project8/9.jpg",
      "../images/projects/thumbnails/project8/10.jpg",
      "../images/projects/thumbnails/project8/11.jpg",
      "../images/projects/thumbnails/project8/12.jpg",
      "../images/projects/thumbnails/project8/13.jpg",
      "../images/projects/thumbnails/project8/14.jpg",
    ],
    videoPath: [
      {
        path: "/images/projects/videos/project-8.mp4",
        poster: "../images/projects/videoPoster/project-8.jpg",
      },],
  },
  {
    id: 8,
    path: "../images/projects/project-9.jpg",
    diffSizePath:"../images/projects/diffSize/project-9.jpg",
    pathL: "../images/projects/large/project-9.jpg",
    heading: "Residential Home (9533 Granville Ave)",
    location: "Richmond, BC",
    detail: "",
    introThumb: "../images/projects/introThumbs/project-9.jpg",
    thumbnails: [
      "../images/projects/thumbnails/project9/gallery-1.jpg",
      "../images/projects/thumbnails/project9/1.jpg",
      "../images/projects/thumbnails/project9/2.jpg",
      "../images/projects/thumbnails/project9/3.jpg",
    ],
    videoPath: [],
  },
  {
    id: 9,
    path: "../images/projects/project-10.jpg",
    diffSizePath:"../images/projects/diffSize/project-10.jpg",
    pathL: "../images/projects/large/project-10.jpg",
    heading: "Residential Home (638 Beach Crescent)",
    location: "Vancouver, BC",
    detail: "",
    introThumb: "../images/projects/introThumbs/project-10.jpg",
    thumbnails: [
      "../images/projects/thumbnails/project10/gallery-1.jpg",
      "../images/projects/thumbnails/project10/1.jpg",
      "../images/projects/thumbnails/project10/2.jpg",
      "../images/projects/thumbnails/project10/3.jpg",
      "../images/projects/thumbnails/project10/4.jpg",
    ],
    videoPath: [],
  },
  {
    id: 10,
    path: "../images/projects/project-11.jpg",
    diffSizePath:"../images/projects/diffSize/project-11.jpg",
    pathL: "../images/projects/large/project-11.jpg",
    heading: "Residential Home (Steveston)",
    location: "Richmond, BC",
    detail: "",
    introThumb: "../images/projects/introThumbs/project-11.jpg",
    thumbnails: [
      "../images/projects/thumbnails/project11/gallery-1.jpg",
      "../images/projects/thumbnails/project11/1.jpg",
      "../images/projects/thumbnails/project11/2.jpg",
      "../images/projects/thumbnails/project11/3.jpg",
      "../images/projects/thumbnails/project11/4.jpg",
      "../images/projects/thumbnails/project11/5.jpg",
      "../images/projects/thumbnails/project11/6.jpg",
      "../images/projects/thumbnails/project11/7.jpg",
      "../images/projects/thumbnails/project11/8.jpg",
      "../images/projects/thumbnails/project11/9.jpg",
    ],
    videoPath: [],
  },
];
