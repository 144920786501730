import { useEffect, useRef, useState } from "react";
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer"
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function Root() {
  const location = useLocation();
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    // window.location.reload();
    if (window.matchMedia("(max-width: 767px)").matches) {
      window.scrollTo(0, 0);
      setMatches(true)
    }
    let ctx = gsap.context(() => {

      gsap.to(".page-wrapper", {
        opacity: 1,
        ease: "power2.easeOut",
        duration: 1,
      });
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 100);

    });

    return () => ctx.revert();
  },[]);
  return (
    <>
      <div className="page-wrapper">
        <Header/>
        <Outlet />
        <ScrollRestoration />

        {location.pathname === "/contact" ? matches ? <Footer/> : null : <Footer />}
      </div>
    </>
  );
}

export default Root;
