import {useEffect} from 'react'
import axios from 'axios'
import './style.css'

const EmailSubscribe = () => {

  function serializeForm(form) {
    const formData = new FormData(form);
    const params = new URLSearchParams();
    for (const [key, value] of formData) {
      params.append(key, value);
    }
    return params.toString();
  }
  
 const handleSubmit = (event)=>{
  event.preventDefault();
  const form = event.target;
  const serializedData = serializeForm(form);

  axios.post('https://constec-backend-psi.vercel.app/subscribe-email', serializedData)
  //axios.post('/subscribe-email', serializedData)
  .then(response => {
    console.log(response.data)
    document.querySelector('#subscription-message').textContent = "Subscription successful!";
    document.querySelector('#email-subscription-form').reset(); // Clear the form
  })
  .catch(error => {
    document.querySelector('#subscription-message').textContent = 'Subscription failed.';
  });

 }

  return (
    <div className="emailSubscribe-container">
      <form className="emailSubscribe" id="email-subscription-form"  onSubmit={handleSubmit}>
          <input className="email" type="email" placeholder="Your email" id="email" name="email" required/>
          <button type="submit" className="submit-btn">
            <img src="/images/icon/arrow-right-white.png" alt="submit btn" />
          </button>
      </form>
      <p>By signing up to our newsletter you agree to our Terms and Conditions</p>
      <div id="subscription-message"></div>
    </div>
  )
}

export default EmailSubscribe
