
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ReactLenis } from "@studio-freight/react-lenis";
import "./style.css";

gsap.registerPlugin(ScrollTrigger);

const Intro = ({contents, sub, path}) => {
  const introRef = useRef(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      const intro = introRef.current;

      gsap.from(intro, {
        opacity:0,
        y: 100,
        duration:1,
        ease: "power3",
        transformOrigin: "center top",
        scrollTrigger: {
          trigger: intro,
          start: "top 95%",
          //end: "top -100%",
          end: "bottom",
          toggleActions: "play reverse play reverse",
        },
      });
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 100);
  })

    return () => ctx.revert();
  }, []);
  return (
    <ReactLenis root options={{ smoothTouch: true }}>
    <div ref={introRef} className="intro-wrapper">
      <h3>
        {contents}
      </h3> 
      <Link to={path}>{sub}
        <img src="../images/icon/arrow-right.png" alt="arrow icon" />
      </Link>
    </div>
    </ReactLenis>
  );
};

export default Intro;
 